/**
 * Vue插件：$api
 * 依赖http.js
 * config另加了1个参数：
 * isShowErrorToast: Boolean，默认值为true，是否显示错误toast
 */
import { _http } from './http'

// PLUGIN NAME
const pluginName = '$api'
const plugin = {}

function init (Vue, options) {
  // const store = options.store
  // const router = options.router
  const http = _http(options.axiosConfig)
  const networkError = {
    code: 404,
    message: '网络错误，请重试!'
  }

  // CLASS DEFINITION
  function Api () {}

  // get请求
  Api.prototype.get = function (...args) {
    return ajax('get', args[0], args[1])
  }

  // post请求
  Api.prototype.post = function (...args) {
    const postData = args[1]
    const config = args[2]
    return ajax('post', args[0], config, postData)
  }

  // put请求
  Api.prototype.put = function (...args) {
    const postData = args[1]
    const config = args[2]
    return ajax('put', args[0], config, postData)
  }

  // delete请求
  Api.prototype.delete = function (...args) {
    return ajax('delete', args[0], args[1])
  }

  // 发起ajax请求
  function ajax (method, url, config, postData) {
    if (!config) config = {}
    if (!config.headers) config.headers = {}
    config.isShowErrorToast = config.isShowErrorToast === undefined || config.isShowErrorToast === true || config.isShowErrorToast === 1
    if (!config.params) config.params = {}
    if (!postData) postData = {}

    // 请求参数添加微信的openid
    // if (method === 'get' || method === 'delete') {
    //   Object.assign(config.params, {
    //     openid: store.state.app.user.weixinOpenid
    //   })
    // }
    // if (method === 'post' || method === 'put') {
    //   Object.assign(postData, {
    //     openid: store.state.app.user.weixinOpenid
    //   })
    // }

    // 请求头添加微信的openid
    // Object.assign(config.headers, {
    //   Authorization: `Bearer ${store.state.app.user.weixinOpenid}`
    // })

    return new Promise((resolve, reject) => {
      if (method === 'get') { // get
        http.get(url, config)
          .then((response) => {
            handle(response)
          })
          .catch(() => {
            config.isShowErrorToast 
            reject(networkError)
          })
      } else if (method === 'post') { // post
        http.post(url, postData, config)
          .then((response) => {
            handle(response)
          })
          .catch(() => {

            config.isShowErrorToast 
            reject(networkError)
          })
      } else if (method === 'put') { // put
        http.put(url, postData, config)
          .then((response) => {
            handle(response)
          })
          .catch(() => {
            config.isShowErrorToast
            reject(networkError)
          })
      } else if (method === 'delete') { // delete
        http.delete(url, config)
          .then((response) => {
            handle(response)
          })
          .catch(() => {
            config.isShowErrorToast 
            reject(networkError)
          })
      }

      // 处理响应数据
      function handle (response) {
        // 获取成功，code值为200
        if (response.code === '200') {
          resolve(response)
          return
        }

        // 获取失败，token过期或无效，退出登录并跳转到登录页
        // if (response.code === 401) {
        //   store.commit('app/user/LOGOUT')
        //   router.push({ name: 'Login' }, () => {})
        // }

        // 获取失败，其它情况
        config.isShowErrorToast 
        reject(response)
      }
    })
  }

  return new Api()
}

// install
plugin.install = (Vue, options) => {
  // 添加Vue的实例方法
  Object.defineProperty(Vue.prototype, pluginName, { value: init(Vue, options) })
}

export default plugin
