<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="12">
      <!-- <el-button
        icon="el-icon-upload2"
        type="primary"
        size="mini"
        @click="importFunc"
      >
        导入
      </el-button> -->
      <el-button
        icon="el-icon-download"
        type="primary"
        size="mini"
        @click="exportFunc"
      >
        导出
      </el-button>
    </el-row>

    <el-table
      ref="tableRef"
      border
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="645 + 'px'"
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img"></el-empty>
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in colums"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template v-slot="scope">
          <span v-if="col.prop === 'matchingState'">
            <el-tag :type="matchingStateMap[scope.row.matchingState].type">
              {{ matchingStateMap[scope.row.matchingState].label }}
            </el-tag>
          </span>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>

    <InputData
      ref="inputData"
      name="file"
      :userId="userId"
      :limit="1"
      :size="2"
      :queryForm="queryForm"
      @fetch-data="fetchData"
      @update-value="updateQueryForm"
    ></InputData>
  </div>
</template>

<script>
import InputData from "./components/inputData.vue";

export default {
  name: "GrnTableList",
  components: { InputData },
  data() {
    return {
      img: require("../../assets/images/empty.png"),
      list: null,
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 20,
      },

      colums: [
        {
          prop: "supplierName",
          label: "供应商名称",
          width: 200,
          fixed: "left",
        },
        { prop: "supplierCode", label: "供应商代码", width: 200 },
        { prop: "grnNumber", label: "GRN号码", width: 150 },
        { prop: "strGrnDate", label: "GRN日期", width: 150 },
        { prop: "productName", label: "材料名称", width: 150 },
        { prop: "packageNum", label: "包码", width: 150 },
        { prop: "orderId", label: "订单号", width: 150 },
        { prop: "noMatchingNum", label: "剩余未匹配数量", width: 120 },
        { prop: "unitPrice", label: "未含税单价", width: 100 },
        { prop: "taxRate", label: "税率", width: 100 },
        { prop: "qtyReceived", label: "GRN数量", width: 150 },
        {
          prop: "accountingAmount",
          label: "GRN核算金额",
          width: 100,
          align: "right",
        },
        {
          prop: "noTaxMoney",
          label: "GRN金额（未税金额）",
          width: 150,
          align: "right",
        },
        {
          prop: "accumulatedMatchingAmount",
          label: "匹配金额",
          width: 100,
          align: "right",
        },
        { prop: "accumulatedMatchingNum", label: "匹配数量", width: 100 },
        {
          prop: "matchingState",
          label: "匹配状态",
          width: 100,
          align: "center",
        },
        { prop: "strMatchingDate", label: "匹配日期", width: 150 },
      ],
      matchingStateMap: {
        0: { label: "未匹配", type: "" },
        1: { label: "部分匹配", type: "warning" },
        2: { label: "完全匹配", type: "success" },
        "-1": { label: "匹配失败", type: "danger" },
      }, // 匹配状态
      userId: "",
    };
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["tableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
    },

    /**
     * @description 导入
     */
    importFunc() {
      this.$refs["inputData"].handleShow();
    },

    /**
     * @description 导入后刷新列表
     */
    updateQueryForm() {
      this.queryForm = { pageNo: 1, pageSize: 20 };
      const { searchData } = this.$store.state.Grn;
      this.$emit("fetch-data", searchData);
    },

    /**
     * @description 导出
     */
    exportFunc() {
      const { searchData } = this.$store.state.Grn;

      const params = {
        supplierNameList: searchData.supplierName,
        supplierCode: searchData.supplierCode,
        grnNumber: searchData.grnNumber,
        matchingStatus: searchData.matchingStatus,
        userId: this.userId,
      };
      // 创建一个隐藏的表单
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "/input/grn/exportGRrn"; // API 端点
      form.target = "_blank"; // 新页面打开

      // 将参数添加到表单中
      Object.keys(params).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      });

      // 将表单添加到文档并提交
      document.body.appendChild(form);
      form.submit();

      // 移除表单
      document.body.removeChild(form);
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post("/input/grn/query", params)
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data.records;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.message);
          console.log("err", err);
          this.listLoading = false;
        });
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      const { searchData } = this.$store.state.Grn;
      this.fetchData(searchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      const { searchData } = this.$store.state.Grn;
      this.fetchData(searchData);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;

  .right-title {
    margin: 12px 10px 0 0;

    .sumTotal {
      font-size: 14px;
      font-weight: bold;
      color: #da5536;
    }
  }
}
::v-deep {
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
}
</style>
