<template>
  <el-dialog
    title="标记非直采"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="closeMarkDialog"
  >
    <span>请确认是都将此发票标记为非直采发票？</span>
    <span slot="footer" class="dialog-footer">
      <div class="footer-tips">取消非直采在“标记非直采”页面</div>
      <div>
        <el-button @click="closeMarkDialog">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "MarkNotDirectlySelect",
  data() {
    return {
      dialogFormVisible: false,
      selectedRow: {},
    };
  },
  created() {},
  methods: {
    /**
     * @description 打开对话框
     */
    showMarkDialog(row) {
      if (Array.isArray(row) && row.length > 0) {
        this.selectedRow = row;
      } else {
        console.warn("父组件传入的数据为:", row); // 处理无效的输入
        return; // 退出函数，避免不必要的操作
      }
      this.dialogFormVisible = true;
    },

    /**
     * @description 关闭对话框
     */
    closeMarkDialog() {
      this.dialogFormVisible = false;
    },

    /**
     * @description 保存
     */
    async save() {
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/signNoStraight", {
          invoiceIds: this.selectedRow.map((item) => item.id),
          userId: this.$route.query.userId,
        })
        .then((res) => {
          this.$message.success(res.message);
          // 请求成功后触发 fetch-data 事件并关闭编辑窗口
          const { invoiceSearchData } = this.$store.state.InvoiceComparison;
          this.$emit("fetch-data", invoiceSearchData);
          this.closeMarkDialog();
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.closeMarkDialog();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-tips {
  font-size: 14px;
  color: #fb6b1d;
}
::v-deep .el-select {
  width: 100%;
}
</style>
