<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    title="数据导入"
    :visible.sync="dialogVisible"
    width="900px"
  >
    <div class="upload">
      <el-alert
        :closable="false"
        :title="`只支持xlsx格式，单次可最多选择${limit}个excel文件，每个文件不可大于${size}M，如果大于${size}M会自动为您过滤`"
        type="info"
      ></el-alert>
      <br />
      <div class="upload-content">
        <el-upload
          ref="upload"
          :action="action"
          :auto-upload="false"
          :close-on-click-modal="false"
          :file-list="fileList"
          :headers="headers"
          :limit="limit"
          :multiple="true"
          :name="name"
          :on-change="handleChange"
          :on-error="handleError"
          :on-exceed="handleExceed"
          :on-preview="handlePreview"
          :on-progress="handleProgress"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :data="{ userId: userId }"
          accept=".xlsx"
          class="upload-demo"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="position: relative; padding-right: 15px; text-align: right"
    >
      <div
        v-if="isShow"
        style="position: absolute; top: 10px; left: 15px; color: #999"
      >
        正在上传中... 当前上传成功数:{{ imgSuccessNum }}张 当前上传失败数:{{
          imgErrorNum
        }}张
      </div>
      <el-button
        :loading="loading"
        size="mini"
        type="primary"
        style="margin-left: 10px"
        @click="submitUpload"
      >
        导入
      </el-button>
      <el-button size="mini" @click="handleClose">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "InputData",
  props: {
    userId: {
      type: String,
      default: "",
      required: true,
    },
    name: {
      type: String,
      default: "file",
      required: true,
    },
    limit: {
      type: Number,
      default: 50,
      required: true,
    },
    size: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      isShow: false,
      dialogVisible: false,
      loading: false,
      action: "/input/grn/excelImport",
      headers: {},
      fileList: [],
      picture: "picture",
      imgNum: 0,
      imgSuccessNum: 0,
      imgErrorNum: 0,
      typeList: null,
    };
  },
  computed: {
    percentage() {
      if (this.allImgNum == 0) return 0;
      return this.$baseLodash.round(this.imgNum / this.allImgNum, 2) * 100;
    },
  },
  methods: {
    submitUpload() {
      this.$refs["upload"].submit();
    },

    /**
     * @description 文件状态改变时的钩子
     */
    handleChange(file, fileList) {
      if (file.size > 1048576 * this.size) {
        fileList.map((item, index) => {
          if (item === file) {
            fileList.splice(index, 1);
          }
        });
        this.fileList = fileList;
      } else {
        this.allImgNum = fileList.length;
      }
    },

    /**
     * @description 文件上传时的钩子
     */
    handleProgress() {
      this.loading = true;
      this.dialogVisible = true;
    },

    /**
     * @description 文件上传成功时的钩子
     */
    handleSuccess(response, file, fileList) {
      this.imgNum = this.imgNum + 1;
      this.imgSuccessNum = this.imgSuccessNum + 1;
      if (fileList.length === this.imgNum) {
        this.$message.success("上传完成!");
      }
      this.loading = false;
      this.handleClose();
      // 通过 $emit 通知父组件刷新列表
      this.$emit("update-value");
    },

    /**
     * @description 文件上传失败时的钩子
     */
    handleError(err, file) {
      this.imgNum = this.imgNum + 1;
      this.imgErrorNum = this.imgErrorNum + 1;
      this.$message.error(
        `文件[${file.raw.name}]上传失败,文件大小为${this.$baseLodash.round(
          file.raw.size / 1024,
          0
        )}KB`
      );
      this.loading = false;
      this.dialogVisible = false;
    },

    /**
     * @description 文件列表移除文件时的钩子
     */
    handleRemove() {
      this.imgNum = this.imgNum - 1;
      this.allNum = this.allNum - 1;
    },

    handlePreview() {},

    /**
     * @description 文件超出个数限制时的钩子
     */
    handleExceed(files) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length}个文件`
      );
    },

    /**
     * @description 打开数据导入对话框
     */
    handleShow() {
      this.dialogVisible = true;
    },

    /**
     * @description 关闭数据导入对话框
     */
    handleClose() {
      this.fileList = [];
      this.picture = "picture";
      this.allImgNum = 0;
      this.imgNum = 0;
      this.imgSuccessNum = 0;
      this.imgErrorNum = 0;
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 10px 20px 30px;
  }
  .el-divider--horizontal {
    margin: 20px 0;
  }
  .el-upload-dragger {
    width: 860px;
  }
}
</style>
