/**
 * Vue插件：$http
 * 依赖axios，https://github.com/axios/axios
 */

import promise from 'es6-promise'
import axios from 'axios'

promise.polyfill()

// PLUGIN NAME
const pluginName = '$http'
const plugin = {}

function init (options) {
  // 创建实例
  const http = axios.create({
    baseURL: options && options.baseURL ?  options.baseURL : '',
    // baseURL: '/api',
    timeout: 60000  
  })

  // 请求拦截器
  http.interceptors.request.use(
    config => {
      // 如果是post，设置content-type
      // 默认使用application/x-www-form-urlencoded，当接口格式需要为application/json时，在对应接口请求里面配置即可

      if (config.method === 'post' || config.method === 'put') {
        if (config.headers['Content-Type'] === 'application/json' || config.headers['Content-Type'] === 'multipart/form-data') {
          return config
        }
        
        // post Content-Type 默认值 application/json
        config.headers['Content-Type'] = 'application/json'

        // post Content-Type 默认值 application/x-www-form-urlencoded
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

        // config.data = qs.stringify(config.data)
        config.data = JSON.stringify(config.data)
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  // 响应拦截器
  http.interceptors.response.use(
    response => {
      return response.data
    },
    error => {
      return Promise.reject(error)
    }
  )

  return http
}

// install
plugin.install = (Vue, options) => {
  // 添加Vue的实例方法
  Object.defineProperty(Vue.prototype, pluginName, { value: init(options) })
}

// 默认导出，作为Vue插件
export default plugin

// 独立使用，不作为Vue插件
export function _http (options) {
  return init(options)
}
