import * as types from "../types";

// 画布数据
const state = {
  invoiceSearchData: {},
  matching: false,
  grnSearchData: {},
  invoiceSelectedRow: [],
  grnSelectedRow: [],
  temporaryOrNot: 0,
};

const mutations = {
  // 存储 发票明细 传来的searchData
  [types.COMP_INVOICE_SEARCH_DATA](state, payload = "") {
    state.invoiceSearchData = payload;
  },
  // 存储 发票明细 传来的 手工状态
  [types.MATCHING](state, payload = "") {
    state.matching = payload;
  },
  // 存储 grn 传来的searchData
  [types.COMP_GRN_SEARCH_DATA](state, payload = "") {
    state.grnSearchData = payload;
  },
  // 存储 发票明细 传来的 table 选中数据
  [types.INVOICE_SELECTED_ROW](state, payload = "") {
    state.invoiceSelectedRow = payload;
  },
  // 存储 grn 传来的 table 选中数据
  [types.GRN_SELECTED_ROW](state, payload = "") {
    state.grnSelectedRow = payload;
  },
  // 存储 grn 传来的 暂存状态
  [types.TEMPORARY_OR_NOT](state, payload = "") {
    state.temporaryOrNot = payload;
  },
};

export default {
  namespaced: true, // 确保开启了命名空间
  state,
  mutations,
};
