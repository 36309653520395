<template>
  <div class="search-container">
    <el-form
      ref="formInline"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      label-width="90px"
      size="mini"
    >
      <el-form-item label="供应商名称" prop="supplierName">
        <el-select
          v-model="formInline.supplierName"
          multiple
          filterable
          default-first-option
          placeholder="请选择供应商名称"
        >
          <el-option
            v-for="(item, index) in supplierOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商代码" prop="supplierCode">
        <el-input
          v-model="formInline.supplierCode"
          placeholder="请输入供应商代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="GRN号码" prop="grnNumber">
        <el-input
          v-model="formInline.grnNumber"
          placeholder="请输入GRN号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单号" prop="orderId">
        <el-input
          v-model="formInline.orderId"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="包码" prop="packageNum">
        <el-input
          v-model="formInline.packageNum"
          placeholder="请输入包码"
        ></el-input>
      </el-form-item>
      <el-form-item label="暂存状态" prop="temporaryStatus">
        <el-select
          v-model="formInline.temporaryStatus"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in temporaryStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          type="primary"
          size="mini"
          @click="submitForm()"
        >
          查询
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetForm('formInline')"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const dayjs = require("dayjs");
export default {
  name: "GrnSearch",
  data() {
    return {
      formInline: {
        supplierName: [],
        supplierCode: "",
        grnNumber: "",
        orderId: "",
        packageNum: "",
        temporaryStatus: "",
      },
      supplierOptions: [],
      temporaryStatusOptions: [
        {
          value: "0",
          label: "取消暂存",
        },
        {
          value: "1",
          label: "暂存 ",
        },
      ],
    };
  },

  watch: {
    "$store.state.InvoiceComparison.invoiceSearchData": {
      handler(newValue) {
        const { supplierName, supplier } = newValue;

        this.formInline.supplierName = supplierName;
        this.formInline.supplierCode = supplier;

        this.$emit("childQuery", this.formInline);
        this.$store.commit(
          "InvoiceComparison/COMP_GRN_SEARCH_DATA",
          this.$baseLodash.cloneDeep(this.formInline)
        );
      },
      deep: true, // 深度监听
    },
    "$store.state.InvoiceComparison.invoiceSelectedRow": {
      handler() {
        const { invoiceSelectedRow, grnSelectedRow } =
          this.$store.state.InvoiceComparison;

        // 当grn列表中没有选中行时，查询发票对应的grn数据
        if (grnSelectedRow.length === 0) {
          const batchNos = invoiceSelectedRow
            .filter((item) => item.batchNo !== "")
            .map((item) => item.batchNo);
          if (batchNos.length > 0) {
            this.formInline.batchNos = batchNos;
          }
          this.$emit("childQuery", this.formInline);
        }
      },
      deep: true, // 深度监听
    },
  },

  created() {
    this.fetchSupplierList();
    this.submitForm();
  },

  methods: {
    /**
     * @description 获取供应商名称
     */
    async fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 查询
     */
    submitForm() {
      this.$store.commit(
        "InvoiceComparison/COMP_GRN_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
      this.$emit("childQuery", this.formInline);
    },

    /**
     * @description 重置
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("childQuery", this.formInline);
      this.$store.commit(
        "InvoiceComparison/COMP_GRN_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
    },

    // 开票日期改变函数
    formatInvoiceData(e) {
      this.formInline.invoiceBeginTime = dayjs(e[0]).format("YYYY-MM-DD");
      this.formInline.invoiceEndTime = dayjs(e[1]).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  // max-height: 100px;

  ::v-deep {
    .el-input__inner {
      width: 255px;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
    }
  }
}
</style>
