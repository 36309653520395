<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="12">
      <el-button
        icon="el-icon-star-off"
        type="primary"
        size="mini"
        @click="cancelMarkFunc"
      >
        取消标记
      </el-button>
    </el-row>

    <el-table
      ref="tableRef"
      border
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="690 + 'px'"
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img"></el-empty>
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in colums"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template v-slot="scope">
          <span v-if="col.prop === 'invoiceLine'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
const invoiceLineEnum = {
  p: "普通发票（电票）",
  c: "普通发票（纸质）",
  s: "专用发票（纸质）",
  b: "专用发票（电子）",
  bs: "数电专票（电子）",
  pc: "数电普票（电子）",
  es: "数电专票（纸质）",
  ec: "数电普票（纸质）",
};

export default {
  name: "markNotDirSelTableList",
  components: {},
  data() {
    return {
      img: require("../../assets/images/empty.png"),
      list: null,
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 20,
      },

      colums: [
        { prop: "supplierName", label: "供应商名称", width: 200 },
        // { prop: "elecInvoiceNumber", label: "数电发票号码" },
        { prop: "invoiceLine", label: "发票种类", width: 120 },
        { prop: "invoiceCode", label: "发票代码" },
        { prop: "invoiceNumber", label: "发票号码" },
        { prop: "goodsName", label: "项目名称", width: 200 },
        { prop: "selfOrderId", label: "订单号" },
        { prop: "goodsNum", label: "数量" },
        { prop: "goodsPrice", label: "未含税单价", align: "right" },
        { prop: "taxFreeAmount", label: "未税金额", align: "right" },
        { prop: "taxRate", label: "税率" },
        { prop: "invoiceTime", label: "发票日期" },
        { prop: "supplier", label: "供应商代码" },
        { prop: "procureDate", label: "标识时间" },
      ],
      userId: "",
    };
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["tableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post(
          "/input/markNonDirectProcure/queryMarkNonDirectProcureOrder",
          params
        )
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.message);
          console.log("err", err);
          this.listLoading = false;
        });
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      const { searchData } = this.$store.state.MarkNotDirSelected;
      this.fetchData(searchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      const { searchData } = this.$store.state.MarkNotDirSelected;
      this.fetchData(searchData);
    },

    /**
     * @description 格式化列表内容
     */
    formatColumn(row, column) {
      if (column === "invoiceLine") {
        return invoiceLineEnum[row.invoiceLine]; // 根据枚举映射显示
      }
      return row[column]; // 默认返回原始值
    },

    /**
     * @description 取消标记
     */
    async cancelMarkFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("未选中任何行");
        return false;
      }
      this.$confirm("是否取消选中发票的直采标记？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const orderIds = this.selectedRows.map((item) => item.orderId);
          const params = {
            orderIds: orderIds,
            userId: this.userId,
          };
          this.$api
            .post("/input/markNonDirectProcure/cancelMark", params)
            .then((res) => {
              console.log("res", res);
              this.$message.success("取消直采标记成功!");
              this.queryForm = { pageNo: 1, pageSize: 20 };
              const { searchData } = this.$store.state.MarkNotDirSelected;
              this.fetchData(searchData);
            })
            .catch((err) => {
              this.$message.error(err.message);
              console.log("err", err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;

  .right-title {
    margin: 12px 10px 0 0;

    .sumTotal {
      font-size: 14px;
      font-weight: bold;
      color: #da5536;
    }
  }
}
::v-deep {
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
}
</style>
