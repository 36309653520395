<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="closeEdit"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="供应商名称" prop="supplierName">
        <el-input
          v-model.trim="form.supplierName"
          autocomplete="off"
          placeholder="请输入供应商名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="供应商代码" prop="supplier">
        <el-input
          v-model="form.supplier"
          autocomplete="off"
          placeholder="请输入供应商代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="供应商税号" prop="taxRegnNum">
        <el-input
          v-model.trim="form.taxRegnNum"
          autocomplete="off"
          placeholder="请输入供应商税号"
        ></el-input>
      </el-form-item>
      <el-form-item label="采购联系人" prop="notifyEmail">
        <el-input
          v-model.trim="form.notifyEmail"
          autocomplete="off"
          placeholder="请输入采购联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="Branch" prop="branch">
        <el-input
          v-model.trim="form.branch"
          autocomplete="off"
          placeholder="请输入Branch"
        ></el-input>
      </el-form-item>
      <el-form-item label="供应商锁定标识" prop="onHold">
        <el-select v-model="form.onHold" clearable placeholder="请选择">
          <el-option
            v-for="item in onHoldStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否可以开采购订单" prop="purchOrdAllowed">
        <el-select
          v-model="form.purchOrdAllowed"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in purchStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeEdit">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "AddOrEditProvider",
  data() {
    return {
      isEdit: "",
      form: {
        supplierName: "",
        supplier: "",
        taxRegnNum: "",
        notifyEmail: "",
        branch: "",
      },
      onHoldStatusList: [
        {
          value: "Y",
          label: "已锁定",
        },
        {
          value: "N",
          label: "未锁定",
        },
      ],
      purchStatusList: [
        {
          value: "Y",
          label: "是",
        },
        {
          value: "N",
          label: "否",
        },
      ],
      rules: {
        supplierName: [
          { required: true, trigger: "blur", message: "请输入供应商名称" },
        ],
      },
      title: "",
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    /**
     * @description 打开新增/编辑对话框
     */
    showEdit(row) {
      console.log("打开新增/编辑对话框", row);

      if (!row.id) {
        this.isEdit = "N";
        this.title = "新增供应商";
      } else {
        this.isEdit = "Y";
        this.title = "编辑供应商";
        this.form = Object.assign({}, row);
      }
      this.dialogFormVisible = true;
    },

    /**
     * @description 关闭新增/编辑对话框
     */
    closeEdit() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },

    /**
     * @description 保存
     */
    async save() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const apiUrl =
          this.isEdit === "Y"
            ? "/input/supplier-basic-info/edit"
            : "/input/supplier-basic-info/add";
        const { userId } = this.$route.query;

        // 发起请求，提交表单数据
        this.$api
          .post(apiUrl, { ...this.form, userId: userId })
          .then((res) => {
            console.log("res", res);
            this.$message.success(
              this.isEdit === "Y" ? "编辑供应商成功" : "新增供应商成功"
            );
            // 请求成功后触发 fetch-data 事件并关闭编辑窗口
            const { searchData } = this.$store.state.Supplier;
            this.$emit("fetch-data", searchData);
            this.closeEdit();
          })
          .catch((err) => {
            this.$message.error(err.message);
            this.closeEdit();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
