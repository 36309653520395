<template>
  <div class="index-container">
    <search @childQuery="childQuery"></search>

    <table-list ref="tableList"></table-list>
  </div>
</template>

<script>
import search from "./search.vue";
import TableList from "./tableList.vue";

export default {
  name: "markNotDirSelected",
  components: { search, TableList },
  data() {
    return {};
  },
  created() {},
  methods: {
    childQuery(data) {
      this.$nextTick(() => {
        // dom元素更新后执行
        this.$refs["tableList"].fetchData(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.index-container {
  padding: 0 !important;
  margin: 0 !important;
  background: #f5f7f8 !important;
}
</style>
