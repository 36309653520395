<template>
  <div class="search-container">
    <el-form
      ref="formInline"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      label-width="100px"
      size="mini"
    >
      <el-form-item label="供应商名称" prop="supplierName">
        <el-select
          v-model="formInline.supplierName"
          multiple
          filterable
          default-first-option
          placeholder="请选择供应商名称"
        >
          <el-option
            v-for="(item, index) in supplierOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商代码" prop="supplier">
        <el-input
          v-model="formInline.supplier"
          placeholder="请输入供应商代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNumber">
        <el-input
          v-model="formInline.invoiceNumber"
          placeholder="请输入发票号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票匹配状态" prop="compareStatus">
        <el-select
          v-model="formInline.compareStatus"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in compareStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="导入状态" prop="importStatus">
        <el-select
          v-model="formInline.importStatus"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in importStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开票日期" prop="invoiceDate">
        <el-date-picker
          v-model="formInline.invoiceDate"
          type="daterange"
          :clearable="false"
          @change="formatInvoiceData"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          icon="el-icon-search"
          type="primary"
          size="mini"
          @click="submitForm()"
        >
          查询
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetForm('formInline')"
        >
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "InvoiceSearch",
  data() {
    return {
      formInline: {
        supplierName: [],
        supplier: "",
        invoiceNumber: "",
        compareStatus: "",
        importStatus: "",
        invoiceDate: [],
        invoiceBeginTime: "",
        invoiceEndTime: "",
      },
      supplierOptions: [],
      compareStatusOptions: [
        {
          value: "0",
          label: "未匹配",
        },
        {
          value: "6",
          label: "数量匹配不成功",
        },
        {
          value: "7",
          label: "未税金额匹配不成功",
        },
        {
          value: "8",
          label: "退回匹配",
        },
        {
          value: "-1",
          label: "初始匹配失败",
        },
        {
          value: "-2",
          label: "包码匹配失败",
        },
      ],
      importStatusOptions: [
        {
          value: "0",
          label: "未导入",
        },
        {
          value: "1",
          label: "导入冲回",
        },
        {
          value: "2",
          label: "导入失败",
        },
        {
          value: "3",
          label: "导入冲回",
        },
      ],
    };
  },
  created() {
    this.fetchSupplierList();
    this.submitForm();
  },
  methods: {
    /**
     * @description 获取供应商名称
     */
    async fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 查询
     */
    submitForm() {
      this.$store.commit(
        "InvoiceComparison/COMP_INVOICE_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
      this.$emit("childQuery", this.formInline);
    },

    /**
     * @description 重置
     */
    resetForm(formName) {
      this.formInline.invoiceBeginTime = "";
      this.formInline.invoiceEndTime = "";
      this.$refs[formName].resetFields();

      this.$emit("childQuery", this.formInline);
      this.$store.commit(
        "InvoiceComparison/COMP_INVOICE_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
    },

    // 开票日期改变函数
    formatInvoiceData(e) {
      this.formInline.invoiceBeginTime = dayjs(e[0]).format("YYYY-MM-DD");
      this.formInline.invoiceEndTime = dayjs(e[1]).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  border-right: 1px solid#e3e8e9;
  // max-height: 100px;

  ::v-deep {
    .el-input__inner {
      width: 255px !important;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
    }
  }
}
</style>
