import * as types from "../types";

// 画布数据
const state = {
  searchData: {},
};

const mutations = {
  // 存储页面传来的searchData
  [types.ENTRY_FAILURE_SEARCH_DATA](state, payload = "") {
    state.searchData = payload;
  },
};

export default {
  namespaced: true, // 确保开启了命名空间
  state,
  mutations,
};
