<template>
  <div class="search-container">
    <el-form
      ref="formInline"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      label-width="100px"
      size="mini"
    >
      <el-form-item label="供应商名称" prop="supplierName">
        <el-select
          v-model="formInline.supplierName"
          multiple
          filterable
          default-first-option
          placeholder="请选择供应商名称"
        >
          <el-option
            v-for="(item, index) in supplierOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商代码" prop="supplier">
        <el-input
          v-model="formInline.supplier"
          placeholder="请输入供应商代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNumber">
        <el-input
          v-model="formInline.invoiceNumber"
          placeholder="请输入发票号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单号" prop="selfOrderId">
        <el-input
          v-model="formInline.selfOrderId"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮件状态" prop="emailStatus">
        <el-select
          v-model="formInline.emailStatus"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in emailStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submitForm()">
          查询
        </el-button>
        <el-button icon="el-icon-refresh" @click="resetForm('formInline')">
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ReInvListSearch",
  data() {
    return {
      formInline: {
        supplierName: [],
        supplier: "",
        invoiceNumber: "",
        selfOrderId: "",
        emailStatus: "",
      },
      supplierOptions: [],
      emailStatusOptions: [
        {
          value: "0",
          label: "未发件",
        },
        {
          value: "1",
          label: "已发送",
        },
        {
          value: "2",
          label: "发送失败",
        },
      ],
    };
  },
  created() {
    this.fetchSupplierList();
    this.submitForm();
  },
  methods: {
    /**
     * @description 获取供应商名称
     */
    async fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          console.log("supplierOptions", res);
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    /**
     * @description 查询
     */
    submitForm() {
      this.$store.commit(
        "ReturnInvoiceList/RETURN_INVOICE_LIST_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
      this.$emit("childQuery", this.formInline);
    },

    /**
     * @description 重置
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("childQuery", this.formInline);
      this.$store.commit(
        "ReturnInvoiceList/RETURN_INVOICE_LIST_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  // max-height: 100px;

  ::v-deep {
    .el-input__inner {
      width: 255px;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
    }
  }
}
</style>
