<template>
  <el-dialog
    title="强制匹配"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="closesMatchingDialog"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="强制匹配密码" prop="forceMatchPwd">
        <el-input
          v-model.trim="form.forceMatchPwd"
          autocomplete="off"
          placeholder="请输入强制匹配密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closesMatchingDialog">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ForcedMatching",
  data() {
    return {
      form: {
        forceMatchPwd: "",
      },
      invoiceSelectedRow: {},
      grnSelectedRow: {},
      rules: {
        forceMatchPwd: [
          { required: true, trigger: "blur", message: "请输入强制匹配密码" },
        ],
      },
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    /**
     * @description 打开对话框
     */
    showMatchingDialog(invoiceRow, grnRow) {
      this.invoiceSelectedRow = invoiceRow; // 安全地获取第一个元素
      this.grnSelectedRow = grnRow; // 安全地获取第一个元素
      this.dialogFormVisible = true;
    },

    /**
     * @description 关闭对话框
     */
    closesMatchingDialog() {
      this.$refs["form"].resetFields();
      this.dialogFormVisible = false;
    },

    /**
     * @description 保存
     */
    async save() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.form.invoiceIds = this.invoiceSelectedRow.map((item) => {
          return item.id;
        }); // 发票主键id数组
        this.form.grnIds = this.grnSelectedRow.map((item) => {
          return item.id;
        }); // grn主键id数组
        const { userId } = this.$route.query;
        const { grnSelectedRow } = this.$store.state.InvoiceComparison;

        // 发起请求，提交表单数据
        this.$api
          .post("/input/invoiceCompareinvoiceCompare/forceMatch", {
            ...this.form,
            userId: userId,
            grnInformations: grnSelectedRow,
          })
          .then((res) => {
            console.log("res", res);
            this.$message.success(res.message);
            // 请求成功后触发 fetch-data 事件并关闭编辑窗口
            const { invoiceSearchData } = this.$store.state.InvoiceComparison;
            this.$emit("fetch-data", invoiceSearchData);
            this.closesMatchingDialog();
          })
          .catch((err) => {
            this.$message.error(err.message);
            this.closesMatchingDialog();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
