<template>
  <el-dialog
    title="导入发票"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="closeImportEdit"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="发票代码" prop="invoiceCode">
        <el-input
          v-model.trim="form.invoiceCode"
          autocomplete="off"
          placeholder="请输入发票代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNumber">
        <el-input
          v-model="form.invoiceNumber"
          autocomplete="off"
          placeholder="请输入发票号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="数电发票号码" prop="elecInvoiceNumber">
        <el-input
          v-model.trim="form.elecInvoiceNumber"
          autocomplete="off"
          placeholder="请输入数电发票号码"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeImportEdit">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "MarkBack",
  data() {
    return {
      form: {
        invoiceCode: "",
        invoiceNumber: "",
        elecInvoiceNumber: "",
      },
      rules: {},
      dialogFormVisible: false,
    };
  },
  created() {},
  methods: {
    /**
     * @description 打开对话框
     */
    showImportDialog(row) {
      console.log("打开对话框", row);
      this.dialogFormVisible = true;
    },

    /**
     * @description 关闭对话框
     */
    closeImportEdit() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },

    /**
     * @description 保存
     */
    async save() {
      this.$refs["form"].validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const { userId } = this.$route.query;
        // 发起请求，提交表单数据
        this.$api
          .post("/input/invoiceCompareinvoiceCompare/importInvoice", {
            ...this.form,
            userId: userId,
          })
          .then((res) => {
            this.$message.success(res.message);
            // 请求成功后触发 fetch-data 事件并关闭编辑窗口
            const { invoiceSearchData } = this.$store.state.InvoiceComparison;
            this.$emit("fetch-data", invoiceSearchData);
            this.closeImportEdit();
          })
          .catch((err) => {
            this.$message.error(err.message);
            this.closeImportEdit();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
