import Vue from "vue";
import VueRouter from "vue-router";
// 发票比对
import InvoiceComparison from "../views/invoiceComparison/index.vue";
// Grn
import Grn from "../views/grn/index.vue";
// 供应商
import Supplier from "../views/supplier/index.vue";
// 标记非直采
import MarkNotDirSelected from "../views/markNotDirSelected/index.vue";
// 退回发票清单
import ReturnInvoiceList from "../views/returnInvoiceList/index.vue";
// 完全匹配
import PerfectMatch from "../views/perfectMatch/index.vue";
// 历史记录
import HistoricalRecord from "../views/historicalRecord/index.vue";
// 入账失败记录
import EntryFailure from "../views/entryFailure/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/invoiceComparison",
    name: "invoiceComparison",
    component: InvoiceComparison,
  },
  {
    path: "/grn",
    name: "grn",
    component: Grn,
  },
  {
    path: "/supplier",
    name: "supplier",
    component: Supplier,
  },
  {
    path: "/markNotDirSelected",
    name: "markNotDirSelected",
    component: MarkNotDirSelected,
  },
  {
    path: "/returnInvoiceList",
    name: "returnInvoiceList",
    component: ReturnInvoiceList,
  },
  {
    path: "/perfectMatch",
    name: "perfectMatch",
    component: PerfectMatch,
  },
  {
    path: "/historicalRecord",
    name: "historicalRecord",
    component: HistoricalRecord,
  },
  {
    path: "/entryFailure",
    name: "entryFailure",
    component: EntryFailure,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
