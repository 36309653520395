<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="12">
      <!-- <el-button
        icon="el-icon-folder-add"
        type="primary"
        size="mini"
        @click="addOrEditFunc"
      >
        新增供应商
      </el-button>
      <el-button icon="el-icon-upload2" size="mini" @click="batchImportFunc">
        批量导入
      </el-button> -->
      <el-button
        icon="el-icon-download"
        type="primary"
        size="mini"
        @click="batchExportFunc"
      >
        批量导出
      </el-button>
      <el-button icon="el-icon-delete" size="mini" @click="batchDeleteFunc">
        批量删除
      </el-button>
    </el-row>

    <el-table
      ref="tableRef"
      border
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="690 + 'px'"
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img"></el-empty>
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in colums"
        :key="`unique-${index}-${col.id}`"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template v-slot="scope">
          <!-- 如果你要给特定列添加 tag，可以用条件判断 -->
          <el-tag
            v-if="col.prop === 'onHold'"
            :type="getOnHoldTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === "Y" ? "已锁定" : "未锁定" }}
          </el-tag>
          <el-tag
            v-else-if="col.prop === 'purchOrdAllowed'"
            :type="getPurchTagType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === "Y" ? "是" : "否" }}
          </el-tag>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="操作"
        fixed="right"
        width="100"
      >
        <template #default="{ row }">
          <el-button type="text" @click="addOrEditFunc(row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>

    <!-- 新增/编辑供应商 -->
    <AddOrEditProvider
      ref="addOrEditProvider"
      @fetch-data="fetchData"
    ></AddOrEditProvider>
    <!-- 批量导入 -->
    <BatchImport
      ref="batchImport"
      name="file"
      :userId="userId"
      :limit="1"
      :size="2"
      @fetch-data="fetchData"
    ></BatchImport>
  </div>
</template>

<script>
import AddOrEditProvider from "./components/addOrEditProvider.vue";
import BatchImport from "./components/batchImport.vue";

export default {
  name: "SupplierTableList",
  components: { AddOrEditProvider, BatchImport },
  data() {
    return {
      img: require("../../assets/images/empty.png"),
      list: null,
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 20,
      },

      colums: [
        {
          prop: "supplierName",
          label: "供应商名称",
          fixed: "left",
          width: 200,
        },
        { prop: "supplier", label: "供应商代码" },
        { prop: "taxRegnNum", label: "供应商税号" },
        { prop: "notifyEmail", label: "采购联系人" },
        { prop: "currency", label: "币种" },
        { prop: "branch", label: "Branch" },
        { prop: "onHold", label: "供应商锁定标识", align: "center" },
        {
          prop: "purchOrdAllowed",
          label: "是否可以开采购订单",
          align: "center",
        },
      ],
      userId: "",
    };
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["tableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
    },

    /**
     * @description 新增/编辑供应商
     */
    addOrEditFunc(row) {
      this.$refs["addOrEditProvider"].showEdit(row);
    },

    /**
     * @description 批量导入
     */
    batchImportFunc() {
      this.$refs["batchImport"].handleShow();
    },

    /**
     * @description 批量导出
     */
    batchExportFunc() {
      const { searchData } = this.$store.state.Supplier;
      const baseUrl = "/input/supplier-basic-info/export";
      // 使用encodeURIComponent封装参数
      const encodeParam = (param) => encodeURIComponent(param || "");
      const params = {
        supplierName: encodeParam(searchData.supplierName),
        supplier: encodeParam(searchData.supplier),
        taxRegnNum: encodeParam(searchData.taxRegnNum),
        userId: this.userId,
      };

      // 构建查询字符串
      const queryString = new URLSearchParams(params).toString();
      const url = `${baseUrl}?${queryString}`;
      try {
        window.location.href = url;
      } catch (error) {
        console.error("导出grn时出错：", error);
      }
    },

    /**
     * @description 批量删除
     */
    async batchDeleteFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("未选中任何行");
        return false;
      }
      this.$confirm("此操作将永久删除选中的数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const ids = this.selectedRows.map((item) => item.id).join();
          const params = {
            ids: ids,
            userId: this.userId,
          };
          this.$api
            .post("/input/supplier-basic-info/batchDelete", params)
            .then((res) => {
              console.log("res", res);
              this.$message.success("删除成功!");
              const { searchData } = this.$store.state.Supplier;
              this.fetchData(searchData);
            })
            .catch((err) => {
              this.$message.error(err.message);
              console.log("err", err);
            });
        })
        .catch(() => {});
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post("/input/supplier-basic-info/getList", params)
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data.records;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
          this.listLoading = false;
        });
    },

    /**
     * @description 根据 onHold 的状态返回不同的标签类型
     */
    getOnHoldTagType(value) {
      // console.log("根据 onHold 的状态返回不同的标签类型", value);
      return value === "Y" ? "danger" : "";
    },

    /**
     * @description 根据 purchOrdAllowed 的状态返回不同的标签类型
     */
    getPurchTagType(value) {
      return value === "Y" ? "success" : "danger";
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      const { searchData } = this.$store.state.Supplier;
      this.fetchData(searchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      const { searchData } = this.$store.state.Supplier;
      this.fetchData(searchData);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;

  .right-title {
    margin: 12px 10px 0 0;

    .sumTotal {
      font-size: 14px;
      font-weight: bold;
      color: #da5536;
    }
  }
}
::v-deep {
  .el-table__empty-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
}
</style>
