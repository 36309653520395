/**
 * actions和mutations的事件类型集合
 * 命名规则：组件名_谓语_宾语 = 组件名/谓语_宾语
 * 例如：List_GET_ITEMS = 'List/GET_ITEMS'（组件名：List，谓语：GET，宾语：ITEMS）
 */

/* ---------------------- 发票比对 ----------------------- */
export const COMP_INVOICE_SEARCH_DATA = "COMP_INVOICE_SEARCH_DATA";
export const MATCHING = "MATCHING";
export const COMP_GRN_SEARCH_DATA = "COMP_GRN_SEARCH_DATA";
export const INVOICE_SELECTED_ROW = "INVOICE_SELECTED_ROW";
export const GRN_SELECTED_ROW = "GRN_SELECTED_ROW";
export const TEMPORARY_OR_NOT = "TEMPORARY_OR_NOT";
/* ---------------------- GRN ----------------------- */
export const GRN_SEARCH_DATA = "GRN_SEARCH_DATA";
/* ---------------------- 供应商 ----------------------- */
export const SUPPLIER_SEARCH_DATA = "SUPPLIER_SEARCH_DATA";
/* ---------------------- 标记非直采 ----------------------- */
export const MARK_NOT_DIRSELECTED_SEARCH_DATA = "MARK_NOT_DIRSELECTED_SEARCH_DATA";
/* ---------------------- 退回发票清单 ----------------------- */
export const RETURN_INVOICE_LIST_SEARCH_DATA = "RETURN_INVOICE_LIST_SEARCH_DATA";
/* ---------------------- 完全匹配 ----------------------- */
export const PERFECT_MATCH_SEARCH_DATA = "PERFECT_MATCH_SEARCH_DATA";
/* ---------------------- 历史记录 ----------------------- */
export const HISTORICAL_RECORD_SEARCH_DATA = "HISTORICAL_RECORD_SEARCH_DATA";
/* ---------------------- 入账失败 ----------------------- */
export const ENTRY_FAILURE_SEARCH_DATA = "ENTRY_FAILURE_SEARCH_DATA";
