<template>
  <el-row>
    <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
      <invoice></invoice>
    </el-col>
    <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
      <grn></grn>
    </el-col>

    <!-- 未完全匹配和导入失败发票数量提示 -->
    <tips ref="tipsDialog"></tips>
  </el-row>
</template>

<script>
import Invoice from "./invoice/index.vue";
import Grn from "./grn/index.vue";
import Tips from "./components/tips.vue";

export default {
  name: "InvoiceComparison",
  components: { Invoice, Grn, Tips },
  data() {
    return {};
  },
  mounted() {
    this.$refs["tipsDialog"].showTipsDialog();
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.index-container {
  padding: 0 !important;
  margin: 0 !important;
  background: #f5f7f8 !important;
}
</style>
