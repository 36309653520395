import Vue from "vue";
import Vuex from "vuex";
import InvoiceComparison from "./modules/invoiceComparison";
import Grn from "./modules/grn";
import Supplier from "./modules/supplier";
import MarkNotDirSelected from "./modules/markNotDirSelected";
import ReturnInvoiceList from "./modules/returnInvoiceList";
import PerfectMatch from "./modules/perfectMatch";
import HistoricalRecord from "./modules/historicalRecord";
import EntryFailure from "./modules/entryFailure";

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    InvoiceComparison, // 注册 发票比对 模块
    Grn, // 注册 Grn 模块
    Supplier, // 注册 供应商 模块
    MarkNotDirSelected, // 注册 标记非直采 模块
    ReturnInvoiceList, // 注册 退回发票清单 模块
    PerfectMatch, // 注册 完全匹配 模块
    HistoricalRecord, // 注册 历史记录 模块
    EntryFailure, // 注册 入账失败 模块
  },
});

export default store;
