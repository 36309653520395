import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import * as lodash from "lodash"; // 引入 lodash
import "./style/common.less";
import "element-ui/lib/theme-chalk/index.css";

// 把 lodash 挂载到 Vue 原型上
Vue.prototype.$baseLodash = lodash;

// $api
import api from "@/plugins/api";
import VueClipBoard from "vue-clipboard2";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);
Vue.use(VueClipBoard);
Vue.use(ElementUI);
// 上线前需修改
Vue.use(api, {
  router,
  axiosConfig: {
    // 线上地址
    baseURL: "/",
    // 测试地址
    // baseURL:'http://10.10.2.204:9185'
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
