<template>
  <div class="table-container">
    <el-row style="margin-bottom: 10px" :span="24">
      <el-col :span="16">
        <el-button
          icon="el-icon-refresh"
          type="primary"
          size="mini"
          @click="refreshDataFunc"
        >
          刷新数据
        </el-button>
        <el-button icon="el-icon-back" size="mini" @click="returnFunc">
          退回
        </el-button>
        <el-button icon="el-icon-download" size="mini" @click="exportExcelFunc">
          导出EXCEL
        </el-button>
      </el-col>
      <el-col :span="8" class="table-content-right">
        <el-button
          icon="el-icon-upload2"
          type="primary"
          size="mini"
          @click="importSysproFunc"
        >
          导入syspro
        </el-button>
      </el-col>
    </el-row>

    <el-table
      ref="tableRef"
      border
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :header-cell-style="{
        background: '#f0f2f5',
        color: '#333',
        height: '5px',
      }"
      size="mini"
      :height="645 + 'px'"
      :summary-method="getSummaries"
      show-summary
      @selection-change="setSelectedRows"
    >
      <template slot="empty">
        <el-empty :image-size="120" :image="img"></el-empty>
      </template>
      <el-table-column
        fixed="left"
        align="center"
        show-overflow-tooltip
        type="selection"
      ></el-table-column>
      <el-table-column
        fixed="left"
        align="center"
        width="50"
        show-overflow-tooltip
        type="index"
        label="序号"
      ></el-table-column>
      <el-table-column
        v-for="(col, index) in colums"
        :key="index"
        :type="col.type"
        :prop="col.prop"
        :label="col.label"
        :width="col.width"
        :align="col.align"
        :fixed="col.fixed"
        show-overflow-tooltip
      >
        <!-- 通过 scoped slots 自定义单元格内容 -->
        <template v-slot="scope">
          <span v-if="col.prop === 'invoiceLine'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <span v-else-if="col.prop === 'compareStatus'">
            {{ formatColumn(scope.row, col.prop) }}
          </span>
          <el-tag
            v-else-if="col.prop === 'returnStatus'"
            :type="getReturnStatusType(scope.row[col.prop])"
          >
            {{ scope.row[col.prop] === 0 ? "未退回" : "已退回" }}
          </el-tag>
          <span v-else-if="col.prop === 'procureStatus'">
            {{ scope.row[col.prop] === 0 ? "非直采" : "直采" }}
          </span>
          <span v-else-if="col.prop === 'importStatus'">
            <el-tag :type="importStatusMap[scope.row.importStatus].type">
              {{ importStatusMap[scope.row.importStatus].label }}
            </el-tag>
          </span>
          <span v-else>
            {{ scope.row[col.prop] != null ? scope.row[col.prop] : "-" }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNo"
      :page-size="queryForm.pageSize"
      :layout="layout"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
const invoiceLineEnum = {
  p: "普通发票（电票）",
  c: "普通发票（纸质）",
  s: "专用发票（纸质）",
  b: "专用发票（电子）",
  bs: "数电专票（电子）",
  pc: "数电普票（电子）",
  es: "数电专票（纸质）",
  ec: "数电普票（纸质）",
};
const compareStatusEnum = {
  1: "初始完全匹配成功",
  2: "包码完全匹配成功",
  3: "强制匹配成功",
  5: "手工匹配",
};

export default {
  name: "HisRecTableList",
  components: {},
  data() {
    return {
      img: require("../../assets/images/empty.png"),
      list: [],
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      selectedRows: [], // 存储勾选的表格数据
      elementLoadingText: "正在加载...",
      queryForm: {
        pageNo: 1,
        pageSize: 20,
      },

      colums: [
        { prop: "supplierName", label: "供应商名称" },
        { prop: "supplier", label: "供应商代码" },
        { prop: "invoiceCode", label: "发票代码" },
        { prop: "invoiceNumber", label: "发票号码" },
        // { prop: "elecInvoiceNumber", label: "数电发票号码" },
        { prop: "invoiceLine", label: "发票种类" },
        { prop: "selfOrderId", label: "订单号" },
        { prop: "goodsNum", label: "数量" },
        {
          prop: "taxFreeAmount",
          label: "未税金额",
          align: "right",
        },
        {
          prop: "taxAmountTotal",
          label: "价税合计",
          align: "right",
        },
        {
          prop: "taxTotal",
          label: "税额",
          align: "right",
        },
        {
          prop: "accountingAmount",
          label: "GRN核算金额",
          align: "right",
        },
        { prop: "invoiceTime", label: "发票日期" },
        { prop: "compareStatus", label: "匹配状态" },
        {
          prop: "returnStatus",
          label: "退回状态",
          align: "center",
        },
        { prop: "procureStatus", label: "标记非直采" },
        {
          prop: "importStatus",
          label: "导入状态",
          align: "center",
        },
        { prop: "errorMessage", label: "导入失败原因" },
      ],
      importStatusMap: {
        0: { label: "未导入", type: "" },
        1: { label: "导入成功", type: "success" },
        2: { label: "导入失败", type: "danger" },
        3: { label: "导入冲回", type: "warning" },
        4: { label: "导入中", type: "info" },
      }, // 匹配状态
      userId: "",
    };
  },

  created() {
    // console.log("链接带过来的query参数：", this.$route.query);
    // console.log("当前页路径：", window.location.href);
    // console.log("userId：", this.$route.query);
    this.userId = this.$route.query.userId;
  },

  updated() {
    this.$nextTick(() => {
      this.$refs["tableRef"].doLayout();
    });
  },

  methods: {
    /**
     * @description 监听表格勾选变化
     */
    setSelectedRows(val) {
      this.selectedRows = val;
    },

    /**
     * @description 获取列表数据
     */
    async fetchData(searchData) {
      this.listLoading = true;
      var params = Object.assign({ ...this.queryForm, ...searchData });

      params.userId = this.userId;
      this.$api
        .post("/input/sysPro/querySuccessMatchInvoice", params)
        .then((res) => {
          console.log("res", res);
          this.listLoading = false;
          this.list = res.data;
          this.total = res.total;
        })
        .catch((err) => {
          this.$message.error(err.message);
          console.log("err", err);
          this.listLoading = false;
        });
    },

    /**
     * @description 格式化列表内容
     */
    formatColumn(row, column) {
      if (column === "invoiceLine") {
        return invoiceLineEnum[row.invoiceLine]; // 根据枚举映射显示
      }
      if (column === "compareStatus") {
        return compareStatusEnum[row.compareStatus]; // 根据枚举映射显示
      }
      return row[column.property]; // 默认返回原始值
    },

    /**
     * @description 根据 returnStatus 的状态返回不同的标签类型
     */
    getReturnStatusType(value) {
      return value === 0 ? "info" : "warning";
    },

    /**
     * @description 改变列表每页显示条数
     */
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      const { searchData } = this.$store.state.PerfectMatch;
      this.fetchData(searchData);
    },

    /**
     * @description 改变列表页数
     */
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      const { searchData } = this.$store.state.PerfectMatch;
      this.fetchData(searchData);
    },

    /**
     * @description 合计
     */
    getSummaries(param) {
      const { columns, data } = param;
      const sums = new Array(columns.length).fill("");

      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = "合计";
        } else if (
          [3, 4, 5, 6, 7, 8, 9, 12, 13, 14, 15, 16, 17, 18, 19].includes(index)
        ) {
          // 不做任何操作，已经初始化为""
        } else {
          // 判断是否是数字 不是数字的话 展示空 是数字的话 添加'元'字
          const values = data.map((item) => Number(item[column.property]));
          const validValues = values.filter((value) => !isNaN(value));
          if (validValues.length) {
            const total = validValues.reduce((prev, curr) => prev + curr, 0);
            sums[index] = `${Math.floor(total * 100) / 100} CNY`;
          }
        }
      });

      return sums;
    },

    /**
     * @description 刷新数据
     */
    refreshDataFunc() {
      this.$api
        .post("/input/compare/auto")
        .then((res) => {
          console.log("res", res);
          this.$message.success(res.message);
          this.queryForm = { pageNo: 1, pageSize: 20 };
          const { searchData } = this.$store.state.PerfectMatch;
          this.fetchData(searchData);
        })
        .catch((err) => {
          this.$message.error(err.message);
          console.log("err", err);
        });
    },

    /**
     * @description 退回
     */
    returnFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("未选中任何行");
        return false;
      }

      this.$confirm("请确认是否退回选中数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const orderIds = this.selectedRows.map((item) => item.id);
          const params = {
            orderIds: orderIds,
            userId: this.userId,
          };
          this.$api
            .post("/input/sysPro/successMatchInvoiceReturned", params)
            .then((res) => {
              console.log("res", res);
              this.$message.success(res.message);
              this.queryForm = { pageNo: 1, pageSize: 20 };
              const { searchData } = this.$store.state.PerfectMatch;
              this.fetchData(searchData);
            })
            .catch((err) => {
              this.$message.error(err.message);
              console.log("err", err);
            });
        })
        .catch(() => {});
    },

    /**
     * @description 导出EXCEL
     */
    exportExcelFunc() {
      // const { searchData } = this.$store.state.PerfectMatch;

      const params = {
        orderIds: this.selectedRows.map((row) => row.id),
        userId: this.userId,
      };
      // 创建一个隐藏的表单
      const form = document.createElement("form");
      form.method = "POST";
      form.action = "/input/sysPro/successMatchInvoiceExcelExport"; // API 端点
      form.target = "_blank"; // 新页面打开

      // 将参数添加到表单中
      Object.keys(params).forEach((key) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      });

      // 将表单添加到文档并提交
      document.body.appendChild(form);
      form.submit();

      // 移除表单
      document.body.removeChild(form);
    },

    /**
     * @description 导入syspro
     */
    importSysproFunc() {
      if (this.selectedRows.length === 0) {
        this.$message.warning("未选中任何行");
        return false;
      }

      this.$confirm("请确认是否导入选中数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const orderIds = this.selectedRows.map((item) => item.id);
          const params = {
            orderIds: orderIds,
            userId: this.userId,
          };
          this.$api
            .post("/input/sysPro/sysProImport", params)
            .then((res) => {
              console.log("res", res);
              this.$message.success("导入syspro成功!");
              this.queryForm = { pageNo: 1, pageSize: 20 };
              const { searchData } = this.$store.state.PerfectMatch;
              this.fetchData(searchData);
            })
            .catch((err) => {
              this.$message.error(err.message);
              console.log("err", err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
}
::v-deep {
  .el-table__empty-block {
    min-width: 100%;
    max-width: 100%;
    padding-right: 100%;
  }
}
</style>
