<template>
  <div class="search-container">
    <el-form
      ref="formInline"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      label-width="100px"
      size="mini"
    >
      <el-form-item label="供应商名称" prop="supplierName">
        <el-select
          v-model="formInline.supplierName"
          multiple
          filterable
          default-first-option
          placeholder="请选择供应商名称"
        >
          <el-option
            v-for="(item, index) in supplierOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="供应商代码" prop="supplier">
        <el-input
          v-model="formInline.supplier"
          placeholder="请输入供应商代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="发票号码" prop="invoiceNumber">
        <el-input
          v-model="formInline.invoiceNumber"
          placeholder="请输入发票号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单号" prop="selfOrderId">
        <el-input
          v-model="formInline.selfOrderId"
          placeholder="请输入订单号"
        ></el-input>
      </el-form-item>
      <el-form-item label="journal号" prop="journalNum">
        <el-input
          v-model="formInline.journalNum"
          placeholder="请输入journal号"
        ></el-input>
      </el-form-item>
      <el-form-item label="打印标志" prop="printFlag">
        <el-select
          v-model="formInline.printFlag"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in printFlagOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票入账年" prop="apYear">
        <el-date-picker
          v-model="formInline.apYear"
          type="year"
          format="yyyy"
          value-format="yyyy"
          placeholder="选择发票入账年"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="发票入账月" prop="apMonth">
        <el-select v-model="formInline.apMonth" placeholder="请选择发票入账月">
          <el-option
            v-for="item in monthOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发票录入日期" prop="sysproDate">
        <el-date-picker
          v-model="formInline.sysproDate"
          type="daterange"
          :clearable="false"
          @change="formatInvoiceData"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="发票种类" prop="invoiceLine">
        <el-select
          v-model="formInline.invoiceLine"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in invoiceLineOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submitForm()">
          查询
        </el-button>
        <el-button icon="el-icon-refresh" @click="resetForm('formInline')">
          重置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const dayjs = require("dayjs");

export default {
  name: "HisRecSearch",
  data() {
    return {
      formInline: {
        supplierName: [],
        supplier: "",
        invoiceNumber: "",
        selfOrderId: "",
        journalNum: "",
        printFlag: "",
        apYear: dayjs().format("YYYY"),
        apMonth: "",
        sysproDate: [],
        beginDate: "",
        endDate: "",
        invoiceLine: "",
      },
      supplierOptions: [],
      printFlagOptions: [
        {
          value: "0",
          label: "未打印",
        },
        {
          value: "1",
          label: "已打印",
        },
      ],
      monthOptions: [
        {
          value: "1",
          label: "一月",
        },
        {
          value: "2",
          label: "二月",
        },
        {
          value: "3",
          label: "三月",
        },
        {
          value: "4",
          label: "四月",
        },
        {
          value: "5",
          label: "五月",
        },
        {
          value: "6",
          label: "六月",
        },
        {
          value: "7",
          label: "七月",
        },
        {
          value: "8",
          label: "八月",
        },
        {
          value: "9",
          label: "九月",
        },
        {
          value: "10",
          label: "十月",
        },
        {
          value: "11",
          label: "十一月",
        },
        {
          value: "12",
          label: "十二月",
        },
      ],
      invoiceLineOptions: [
        {
          value: "p",
          label: "普通发票（电票）",
        },
        {
          value: "c",
          label: "普通发票（纸质）",
        },
        {
          value: "s",
          label: "专用发票（纸质）",
        },
        {
          value: "b",
          label: "专用发票（电子）",
        },
        {
          value: "bs",
          label: "数电专票（电子）",
        },
        {
          value: "pc",
          label: "数电普票（电子）",
        },
        {
          value: "es",
          label: "数电专票（纸质）",
        },
        {
          value: "ec",
          label: "数电普票（纸质）",
        },
      ],
    };
  },
  created() {
    this.fetchSupplierList();
    this.submitForm();
  },
  methods: {
    /**
     * @description 获取供应商名称
     */
    async fetchSupplierList() {
      const { userId } = this.$route.query;
      this.$api
        .post("/input/grn/supplierNameQuery", { userId: userId })
        .then((res) => {
          console.log("supplierOptions", res);
          this.supplierOptions = res.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$message.error(err.message);
        });
    },

    // 开票日期改变函数
    formatInvoiceData(e) {
      this.formInline.beginDate = dayjs(e[0]).format("YYYY-MM-DD");
      this.formInline.endDate = dayjs(e[1]).format("YYYY-MM-DD");
    },

    /**
     * @description 查询
     */
    submitForm() {
      this.$store.commit(
        "HistoricalRecord/HISTORICAL_RECORD_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
      this.$emit("childQuery", this.formInline);
    },

    /**
     * @description 重置
     */
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("childQuery", this.formInline);
      this.$store.commit(
        "HistoricalRecord/HISTORICAL_RECORD_SEARCH_DATA",
        this.$baseLodash.cloneDeep(this.formInline)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  padding: 20px 20px 10px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 15px;
  // max-height: 100px;

  ::v-deep {
    .el-input__inner {
      width: 255px !important;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
    }
  }
}
</style>
