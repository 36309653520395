<template>
  <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="400px"
    @close="closeTipsDialog"
  >
    <p>
      当前有
      <span style="color: #fd6e0d">{{ noCompleteMatchNum }}</span>
      张发票未完全匹配
    </p>
    <p>
      当前有
      <span style="color: #fd6e0d">{{ importFailNum }}</span> 张发票导入失败
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="mini" @click="closeTipsDialog"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "Tips",
  data() {
    return {
      dialogVisible: false,
      noCompleteMatchNum: "",
      importFailNum: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    /**
     * @description 打开对话框
     */
    showTipsDialog() {
      this.dialogVisible = true;
    },

    /**
     * @description 关闭对话框
     */
    closeTipsDialog() {
      this.dialogVisible = false;
    },

    /**
     * @description 获取未完全匹配和导入失败发票数量提示
     */
    async fetchData() {
      this.$api
        .post("/input/invoiceCompareinvoiceCompare/getNoMatchAndFailInvoice", {
          userId: this.$route.query.userId,
        })
        .then((res) => {
          this.noCompleteMatchNum = res.data.noCompleteMatchNum;
          this.importFailNum = res.data.importFailNum;
          this.dialogVisible = true;
        })
        .catch((err) => {
          this.$message.error(err.message);
          this.closeTipsDialog();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding: 12px 20px;
  }
}
</style>
